import React, { useEffect, useState } from "react";
import s from "./NewsPageList.module.css";
import API from "../../API";
import { NavLink } from "react-router-dom";
import { ROUTER } from "../../config";
import ContantContainerMain from "../../total/ContantContainerMain";
import ItemComponent from "../ItemComponent";
import PaginationComponent from "../../total/PaginationComponent";

const NewsPageList = () => {
   const [news, setNews] = useState([]);
   const [checkbox, setCheckbox] = useState(false);

   return (
      <div className="columnContainer mt40" >

         <div className="columnLarge">
            <ContantContainerMain>
               <div className={` breadcrumbs`}>
                  {/* <NavLink to={ROUTER.main} className="breadcrumbsFrom">
            Главная
          </NavLink> */}
                  <span className={"breadcrumbsTo"}>  Новости</span>
               </div>
               <div className={s.container}>
                  <div className='titleRowBlock'>
                     <div className='checkboxBlock'>
                        <input
                           onChange={() => setCheckbox(!checkbox)}
                           value={checkbox}
                           className="mainInput"
                           type="checkbox"
                           name="scales"
                        />
                     </div>
                     <div className='titleBlock'>Заголовок</div>
                     <div className='publishedBlock'>Опубликовано</div>
                     <div className='dateBlock'>Дата публикации</div>
                  </div>
                  <div>
                     {news?.news?.list?.map((el) => (
                        <ItemComponent key={el.id} id={el.id} name={el.name} published={el.published} date={el.dateTime} checkboxMain={checkbox} type={"news"} />
                     ))}
                  </div>

                  <PaginationComponent getDataBack={API.getNews} setData={setNews} getData={news} getDataBackKey={'news'} />

               </div>
            </ContantContainerMain>
         </div>

         <div className="columnSmal">
            <NavLink to={ROUTER.admin.newsArticle} className="publishBtn">Добавить новость</NavLink>
         </div>

      </div>
   );
};

export default NewsPageList;
