import React, { useEffect, useState } from "react";
import s from './PaginationComponent.module.css';

const PaginationComponent = ({ getDataBack, setData, getData, getDataBackKey }) => {

   const [currentPage, setCurrentPage] = useState(1);
   const [limit] = useState(10);

   useEffect(() => {
      getDataBack(currentPage, limit)
         .then((data) =>
            setData(data)
         );


      window.scrollTo({ top: 0, behavior: "smooth" });
   }, [currentPage, limit]);

   const totalPages = Math.ceil(getData?.[getDataBackKey]?.all / limit);

   const changePage = (page) => {
      if (page >= 1 && page <= totalPages) {
         setCurrentPage(page);
      }
   };

   const getPaginationItems = () => {
      const pages = [];

      if (currentPage > 3) {
         pages.push(1);
         if (currentPage > 4) {
            pages.push("...");
         }
      }

      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
         pages.push(i);
      }

      if (currentPage < totalPages - 2) {
         if (currentPage < totalPages - 3) {
            pages.push("...");
         }
         pages.push(totalPages);
      }

      return pages;
   };

   return (
      <div className={s.paginationContainer}>
         <span className={s.description}>Страницы:</span>
         <div className={s.pagination}>
            {getPaginationItems().map((page, index) => (
               <div
                  key={index}
                  className={`${s.paginationItem} ${currentPage === page ? s.active : ""
                     } ${page === "..." ? s.disabled : ""}`}
                  onClick={() => typeof page === "number" && changePage(page)}
               >
                  {page}
               </div>
            ))}
         </div>
      </div>
   )
}
export default PaginationComponent;