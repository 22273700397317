export const ROUTER = {
   /* main: "/",
   news: "/novosti", */
   /* newsArticle: "/novosti/:id", */
   documents: "/documents",
   councilDeputies: {
      legalBasisActivity: "/pravovaya-osnova-deyatelnosti",
      symbolism: "/ustav-i-simvolika",
      deputies: {
         /* main: "/deputaty",
         deputies: "/deputaty", */
         chart: "/deputaty/grafik-priyema-deputatov",
         awards: "/deputaty/nagrady-soveta-deputatov",
         rules: "/deputaty/pravila-deputatskoy-etiki",
      },
      compositionStructure: "/sostav-i-struktura",
      writeAdministration: "/napisat-v-administratsiyu",
   },
   contacts: {
      main: "/contacts",
   },
   search: "/search",
   admin: {
      main: '/',
      news: '/novost',
      newsArticle: '/dobavit-novost',
      newsArticleEdit: '/redaktirovat-novost/:id',
      documents: '/dokumenty',
      documentsArticle: '/dobavit-dokumenty',
      documentsArticleEdit: '/redaktirovat-dokumenty/:id',
      deputies: '/deputaty',
      deputiesArticle: '/dobavit-deputaty',
   },
};
