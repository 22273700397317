import React, { useEffect, useState } from "react";
import s from "./DocumentsPageList.module.css";
import API from "../../API";
import { NavLink } from "react-router-dom";
import { ROUTER } from "../../config";
import ContantContainerMain from "../../total/ContantContainerMain";
import ItemComponent from "../ItemComponent";
import PaginationComponent from "../../total/PaginationComponent";

const DocumentsPageList = () => {
   const [documents, setDocuments] = useState([]);
   const [checkbox, setCheckbox] = useState(false);

   return (
      <div className="columnContainer mt40" >

         <div className="columnLarge">
            <ContantContainerMain>
               <div className={`breadcrumbs`}>
                  {/* <NavLink to={ROUTER.main} className="breadcrumbsFrom">
            Главная
          </NavLink> */}
                  <span className={"breadcrumbsTo"}>  Документы</span>
               </div>
               <div className={s.container}>
                  <div className='titleRowBlock'>
                     <div className='checkboxBlock'>
                        <input
                           onChange={() => setCheckbox(!checkbox)}
                           value={checkbox}
                           className="mainInput"
                           type="checkbox"
                           name="scales"
                        />
                     </div>
                     <div className='titleBlock'>Заголовок</div>
                     <div className='publishedBlock'>Опубликовано</div>
                     <div className='dateBlock'>Дата публикации</div>
                  </div>
                  <div>
                     {documents?.document?.list?.map((el) => (
                        <ItemComponent key={el.id} id={el.id} name={el.name} published={true} date={el.created_at} checkboxMain={checkbox} type={'documents'} />
                     ))}
                  </div>

                  <PaginationComponent getDataBack={API.getDocumentations} setData={setDocuments} getData={documents} getDataBackKey={'document'} />

               </div>
            </ContantContainerMain>
         </div>

         <div className="columnSmal">
            <NavLink to={ROUTER.admin.documentsArticle} className="publishBtn">Добавить документ</NavLink>
         </div>

      </div>


   );
};

export default DocumentsPageList;
