import React, { useState, useEffect } from 'react';
import s from './NewsArticlePage.module.css';
import ContantContainerMain from "../../total/ContantContainerMain";

import ReactQuill from 'react-quill';
import './quill.snow.css';
import NewsArticle from '../../Pages/NewsArticle';
import UploadFile from '../../total/UploadFile';
import { ROUTER } from '../../config';
import { NavLink } from 'react-router-dom';

const NewsArticlePage = (props) => {

   const [file, setFile] = useState(null);
   const [previewUrl, setPreviewUrl] = useState(null);

   useEffect(() => {
      if (!file) {
         return;
      }

      setPreviewUrl(window.URL.createObjectURL(file));
   }, [file]);

   const [title, setTitle] = useState('');
   const [value, setValue] = useState('');

   const modules = {
      toolbar: [
         [{ 'header': [1, 2, false] }],
         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
         ['clean']
      ],
   }

   return (
      <div className="columnContainer mt40" >

            <div className="columnLarge">
               <ContantContainerMain>
               <div className={`breadcrumbs`}>
                  <NavLink to={ROUTER.admin.news} className='breadcrumbsFrom'>Новости</NavLink>
                  <span className='breadcrumbsTo'> / Добавить новость </span>
               </div>
               <h1 className="pageTitle mt40">Новость</h1>
               <div className="text mt40">
                  <div className='mt40'>
                     <UploadFile handler={setFile} title={'Загрузите обложку'} type={'single'} />
                     {/* <input type="file" onChange={e => setFile(e.target.files[0])} /> */}
                  </div>

                  <input placeholder='Заголовок' type="text" value={title} onChange={(e) => setTitle(e.target.value)} className="inputTitle mt24" />

                  <div className="mt24">
                     <ReactQuill placeholder='Текст новости' theme="snow" value={value} onChange={setValue} modules={modules} />
                  </div>
               </div>

               <div className="pageTitle mt24">Предпросмотр:</div>

               <NewsArticle img={previewUrl} title={title} text={value} />
               </ContantContainerMain>
            </div>

            <div className="columnSmal">
               <button className="publishBtn">Опубликовать</button>
               <button className="saveWithoutPublishingBtn mt8">Сохранить без публикации</button>
            </div>

      </div>
   )
}
export default NewsArticlePage;