import React, { useState, useEffect, useCallback } from 'react';
import s from './NewsArticlePageEdit.module.css';
import ContantContainerMain from "../../total/ContantContainerMain";

import ReactQuill from 'react-quill';
import './quill.snow.css';
import NewsArticle from '../../Pages/NewsArticle';
import UploadFile from '../../total/UploadFile';
import { ROUTER } from '../../config';
import { NavLink, useParams } from 'react-router-dom';
import API from '../../API';

const NewsArticlePageEdit = (props) => {
   const newsId = useParams().id.slice(3);

   const [currentNews, setCurrentNews] = useState({})

   useEffect(() => {
      API.getItemNews(newsId)
         .then(data => {
            setCurrentNews({ ...data })
         })
   }, [])

   const [file, setFile] = useState(null);
   const [previewUrl, setPreviewUrl] = useState(null);

   useEffect(() => {
      if (!file) {
         return;
      }

      /* setPreviewUrl(window.URL.createObjectURL(file)); */
      handler(window.URL.createObjectURL(file), "image_preview");
   }, [file]);

   const modules = {
      toolbar: [
         [{ 'header': [1, 2, false] }],
         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
         ['clean']
      ],
   }

   const handler = useCallback((value, key) => {
      setCurrentNews({ ...currentNews, [key]: value })
   }, [currentNews])

   return (
      <div className="columnContainer mt40" >

         <div className="columnLarge">
            <ContantContainerMain>
               <div className={`breadcrumbs`}>
                  <NavLink to={ROUTER.admin.news} className='breadcrumbsFrom'>Новости</NavLink>
                  <span className='breadcrumbsTo'> / Редактировать новость </span>
               </div>
               <h1 className="pageTitle mt40">Новость</h1>
               <div className="text mt40">
                  <div className='mt40'>
                     <UploadFile handler={setFile} title={'Загрузите обложку'} type={'single'} />
                     {/* <input type="file" onChange={e => setFile(e.target.files[0])} /> */}
                  </div>

                  <input placeholder='Заголовок' type="text" value={currentNews.name} onChange={(e) => handler(e.target.value, "name")} className="inputTitle mt24" />

                  <div className="mt24">
                     {currentNews.text && <ReactQuill placeholder='Текст новости' theme="snow" value={currentNews.text} onChange={(e) => handler(e, "text")} modules={modules} />}
                     
                  </div>
               </div>

               <div className="pageTitle mt24">Предпросмотр:</div>

               <NewsArticle img={currentNews.image_preview} title={currentNews.name} text={currentNews.text} />
            </ContantContainerMain>
         </div>

         <div className="columnSmal">
            <button className="publishBtn">Опубликовать</button>
            <button className="saveWithoutPublishingBtn mt8">Сохранить без публикации</button>
         </div>

      </div>
   )
}
export default NewsArticlePageEdit;