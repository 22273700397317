import axios from "axios";

const instance = axios.create({
   /* withCredentials: true,
    headers: {
       'API-KEY': 'Ключ'
    }, */
   baseURL: "https://sdhimki.ru/laravel/public/api",
});

const API = {
   getNews: async (page = 1, limit = 12, dateFrom = "", dateTo = "") => {
      return instance
         .get(
            `content/news/list?page=${page}&limit=${limit}`
         ) /* &dateFrom=${dateFrom}&dateTo=${dateTo} */
         .then((response) => response.data)
         .catch(() => {
            console.log("Error");
         });
   },
   getItemNews: async (id) => {
      return instance
         .get(`content/news/item?id=${id}`)
         .then((response) => response.data)
         .catch(() => {
            console.log("Error");
         });
   },

   getDocumentations: async (page, limit = 10, search = "") => {
      return instance
         .get(
            `content/document/list?page=${page}&limit=${limit}&search=${search}`
         )
         .then((response) => response.data)
         .catch(() => {
            console.log("Error");
         });
   },
   getDeputaty: async () => {
      return instance
         .get(
            `content/deputaty/list?limit=50`
         )
         .then((response) => response.data)
         .catch(() => {
            console.log("Error");
         });
   },
   postSubscribeNews: async (email) => {
      return instance
         .post(`subscribe/add`, email)
         .then((response) => response.data)
         .catch(() => {
            console.log("Error");
         });
   },
   postСomplaint: async (data) => {
      return axios
         .post(`https://admhimki.ru/delara/public/api/support/ticket/add`, data)
         .then((response) => response.data)
         .catch(() => {
            console.log("Error");
         });
   },
   getSearch: async (searchData) => {
      return await instance
         .get(`content/search/list?search=${searchData}`)
         .then((response) => response.data)
         .catch(() => {
            console.log("Error")
         })
   },
   postPublished: async (id, published, type) => {
      const formData = new FormData();
         formData.append("id", id)
         formData.append("published", published)
      return await instance
         .post(`/published/${type}`, formData)
         .then((response) => response.data)
         .catch(() => {
            console.log("Error")
         })
   },
   postRemove: async (id, remove) => {
      return await instance
         .get(`/remove?${id}&${remove}`)
         .then((response) => response.data)
         .catch(() => {
            console.log("Error")
         })
   },
};
export default API;