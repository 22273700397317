import React, { useState, useEffect } from 'react';
import ContantContainerMain from '../../total/ContantContainerMain';
import s from './DocumentsArticlePage.module.css';

import UploadFile from '../../total/UploadFile';
import { ROUTER } from '../../config';
import { NavLink } from 'react-router-dom';

const DocumentsArticlePage = (props) => {

   const [file, setFile] = useState(null);

   const [title, setTitle] = useState('');
   const [value, setValue] = useState('');

   return (
      <div className='columnContainer mt40'>

         <div className="">
            <div className="columnLarge">
               <ContantContainerMain>
                  <div className={`breadcrumbs`}>
                     <NavLink to={ROUTER.admin.documents} className='breadcrumbsFrom'>Документы</NavLink>
                     <span className='breadcrumbsTo'> / Добавить документ </span>
                  </div>

                  <h1 className="pageTitle mt40">Документ</h1>
                  <div className="text mt40">
                     <input placeholder='Заголовок документа' type="text" value={title} onChange={(e) => setTitle(e.target.value)} className="inputTitle mt24" />

                     <div className="mt24">
                        <textarea onChange={(e) => setValue(e.target.value)} value={value} className={s.textarea} placeholder='Описание'></textarea>
                     </div>

                     <div className='mt24'>
                        {/* <UploadFile handler={handler} /> */}
                        <UploadFile handler={setFile} title={'Загрузите документы в форматах: doc, docx, xls, xlsx, pdf, zip, rar'} type={'multy'} fileName={title} discription={value} />
                     </div>
                  </div>
               </ContantContainerMain>
            </div>

         </div>

         <div className="columnSmal">
            <button className="publishBtn">Опубликовать</button>
            <button className="saveWithoutPublishingBtn mt8">Сохранить без публикации</button>
         </div>
      </div>
   )
}
export default DocumentsArticlePage;