import React from 'react';
import s from './MenuAdmin.module.css';

import { NavLink } from 'react-router-dom';
import { ROUTER } from '../../config';

const MenuAdmin = (props) => {

   return (
      <div className={`${s.menuContainer} `}>
         <NavLink className={({ isActive }) => (isActive ? `${s.linkMenu} ${s.active}` : `${s.linkMenu}`)} to={ROUTER.admin.news}>
            Новости
         </NavLink>
         <NavLink className={({ isActive }) => (isActive ? `${s.linkMenu} ${s.active}` : `${s.linkMenu}`)} to={ROUTER.admin.documents}>
            Документы
         </NavLink>
         <NavLink className={({ isActive }) => (isActive ? `${s.linkMenu} ${s.active}` : `${s.linkMenu}`)} to={ROUTER.admin.deputies}>
            Депутаты
         </NavLink>
      </div>
   )
}
export default MenuAdmin;